/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    code: "code",
    hr: "hr",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Quantum just-in-time compilation is the compilation of hybrid quantum-classical programs during\nexecution of a program, rather than before execution. PennyLane provides just-in-time compilation\nwith its ", React.createElement(_components.a, {
    href: "https://docs.pennylane.ai/en/stable/introduction/compiling_workflows.html"
  }, React.createElement(_components.code, null, "@qml.qjit"), " decorator"), ", powered by the\n", React.createElement(_components.a, {
    href: "https://github.com/PennyLaneAI/catalyst"
  }, "Catalyst"), " compiler."), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.p, null, "Many programming languages are commonly implemented as ", React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Compiled_language"
  }, "compiled languages"), ",\nmeaning that, before execution, a ", React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Compiler"
  }, "compiler"), " first translates the human-readable source\ncode into low-level instructions (a binary) that are natively understood by a computer.\nThis binary can then be executed with program input. Examples of compiled languages include C++, Fortran, and Rust."), "\n", React.createElement(_components.p, null, "In contrast, other programming languages are more commonly implemented as\n", React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Interpreter_(computing)"
  }, "interpreted languages"), ". Rather than being compiled ahead-of-time, an ", React.createElement(_components.strong, null, "interpreter"), "\nexecutes the program line-by-line, translating each line of high-level code to machine\ninstructions 'on the fly'. Examples of interpreted languages include Python and JavaScript."), "\n", React.createElement(_components.p, null, "As a general rule of thumb, compiled languages result in better performing code, as the compiler\nis given time to analyze the entire program to make the best optimizations available.\nHowever, this results in less portable code, and requires the programmer to provide more information upfront\n(such as variable ", React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Data_type"
  }, "types"), ")."), "\n", React.createElement(_components.p, null, "On the other hand, interpreted languages need less information upfront since the program is executed\non the fly, which is useful for rapid prototyping. However, execution can be slower than compiled\nlanguages."), "\n", React.createElement(_components.p, null, "Just-in-time (JIT) compilation blurs the line between interpreted and compiled languages. In this\nparadigm, portions of code in an interpreted language are compiled to machine code just before execution,\nwhen enough information is available for compilation. When this block is executed again later, the\npreviously optimized compiled block is re-used, with potentially different input. This allows for\na nice middle ground between the rapid prototyping capability of interpreted languages, and the\nspeed of compiled languages."), "\n", React.createElement(_components.p, null, "Various languages and packages make use of JIT compilation for this reason. Examples include\n", React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Julia_(programming_language)"
  }, "Julia"), ", ", React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Google_JAX"
  }, "JAX"), ", and ", React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Numba"
  }, "Numba"), "."), "\n", React.createElement(_components.p, null, "Quantum just-in-time (QJIT) compilation is an extension of this idea, where an entire\nquantum-classical program — including classical processing and control flow of quantum\noperations — is compiled just before execution. This enables rapid quantum algorithm prototyping,\nwhich can easily be scaled up to run on hardware accelerators (such as GPUs and QPUs)."), "\n", React.createElement(_components.p, null, "Examples of quantum software frameworks that support QJIT include PennyLane\n(via the ", React.createElement(_components.a, {
    href: "https://docs.pennylane.ai/en/stable/introduction/compiling_workflows.html"
  }, React.createElement(_components.code, null, "@qml.qjit"), " decorator"), " and ", React.createElement(_components.a, {
    href: "https://docs.pennylane.ai/projects/catalyst/en/stable/"
  }, "Catalyst"), ") and Nvidia's ", React.createElement(_components.a, {
    href: "https://developer.nvidia.com/cuda-quantum"
  }, "CUDA Quantum"), "."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
